import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavigationSocial from './NavigationSocial';
import { STATIC_SERVER_URL } from '../../config/config';

const menuItems = [
  {
    label: 'hírek',
    submenu: [
      { label: 'Legfrissebb', to: '/' },
      { label: 'Klub', to: '/hirek-listazas/klub' },
      { label: 'Első csapat', to: '/hirek-listazas/elso-csapat' },
      { label: 'Második csapat', to: '/hirek-listazas/masodik-csapat' },
      { label: 'Akadémia', to: '/hirek-listazas/akademia' },
      { label: 'Női labdarúgás', to: '/hirek-listazas/noi-labdarugas' },
      { label: 'Mérkőzések', to: '/hirek-listazas/merkozesek' },
      { label: 'Archívum', to: '/archivum' },
    ],
  },
  {
    label: 'mérkőzések',
    submenu: [
      { label: 'Első csapat', to: '/merkozesek/elso-csapat' },
      { label: 'Második csapat', to: '/merkozesek/masodik-csapat' },
      { label: 'Női csapat', to: '/merkozesek/noi-csapat' },
    ],
  },
  {
    label: 'jegyek',
    submenu: [
      { label: 'Jegyvásárlás', href: 'https://jegy.honvedfc.hu', external: true },
      { label: 'Jegyinformációk', to: '/tartalom/belepok' },
    ],
  },
  {
    label: 'csapatok',
    submenu: [
      { label: 'Első csapat', to: '/csapatok/elso-csapat' },
      // { label: 'Második csapat', to: '/csapatok/masodik-csapat' },
      { label: 'akadémia', href: 'http://magyarfutballakademia.hu/', external: true },
      { label: 'Női csapat', to: '/csapatok/noi-csapat' },
    ],
  },
  {
    label: 'média',
    submenu: [
      { label: 'Fotók 2024/25', to: '/media/fotoalbumok' },
      { label: 'Videók', to: '/media/videok' },
      { label: 'Mérkőzések 2024/25', to: '/media/merkozesek' },
      { label: 'Összefoglalók 2024/25', to: '/media/osszefoglalok' },
      { label: 'Sajtó', to: '/tartalom/sajto' },
      // { label: 'Stream', to: '/stream' }, // Uncomment if needed
    ],
  },
  {
    label: 'történelem',
    submenu: [
      { label: 'A Kispesti labdarúgás története', to: '/tartalom/kispesti-labdarugas-tortenete' },
      { label: 'Legek', to: '/tartalom/legek' },
      { label: 'Eredménylista', to: '/tartalom/eredmenyek' },
      { label: 'Bajnokok', to: '/tartalom/bajnokok' },
      { label: 'Kupagyőztesek', to: '/tartalom/kupagyoztesek' },
      { label: 'Válogatottak', to: '/tartalom/valogatottak' },
      { label: 'Bozsik József', to: '/tartalom/bozsik-jozsef' },
      { label: 'Puskás Ferenc', to: '/tartalom/puskas-ferenc' },
      { label: 'Tichy Lajos', to: '/tartalom/tichy-lajos' },
      { label: 'Kocsis Lajos', to: '/tartalom/kocsis-lajos' },
      { label: 'Czibor Zoltán', to: '/tartalom/czibor-zoltan' },
      { label: 'Kocsis Sándor', to: '/tartalom/kocsis-sandor' },
      { label: 'Détári Lajos', to: '/tartalom/detari-lajos' },
      { label: 'Edzőink', to: '/tartalom/edzok' },
      { label: 'Kispesti Futball Ház', to: '/tartalom/kispesti-futball-haz' },
    ],
  },
  {
    label: 'akadémia',
    href: 'http://magyarfutballakademia.hu/',
    external: true,
  },
  {
    label: 'ajándékbolt',
    href: 'https://shop.honvedfc.hu/',
    external: true,
  },
  {
    label: 'Bozsik Aréna',
    submenu: [
      { label: 'Bozsik Aréna weboldal', href: 'http://bozsikarena.hu', external: true },
      { label: 'Fotók', to: '/tartalom/bozsik-arena-fotok' },
      { label: 'VIP Szolgáltatásaink', to: '/hirek/vip-szolgaltatasaink/2021-06-06' },
      { label: 'A Bozsik Stadion története', to: '/tartalom/bozsik-stadion-tortenete' },
      { label: 'Pályarendszabályok', to: '/tartalom/palyarendszabalyok' },
    ],
  },
  {
    label: 'Puskás Alapítvány',
    href: 'https://www.puskasalapitvany.hu/',
    external: true,
  },
  {
    label: 'klub',
    submenu: [
      { label: 'Információk', to: '/tartalom/klub-informaciok' },
      { label: 'Partnerek', to: '/tartalom/partnerek' },
      { label: 'Beszámolók', to: '/tartalom/beszamolok' },
      { label: 'Hirdetmények', to: '/tartalom/hirdetmenyek' },
      { label: 'Szurkolók', to: '/tartalom/szurkolok' },
      { label: 'Kapcsolat', to: '/tartalom/kapcsolat' },
      { label: 'Hírlevél', to: '/hirlevel' },
      { label: 'Letöltések', to: '/tartalom/letoltesek' },
    ],
  },
  {
    label: 'BHSE',
    href: 'https://honved.hu/',
    external: true,
  },
];

function NavigationContent() {
  const [activeClasses, setActiveClasses] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const addActiveClass = (index) => {
    const actives = [];
    for (const i in activeClasses) {
      if (index === parseInt(i)) {
        actives.push(!activeClasses[i]);
      } else {
        actives.push(false);
      }
    }
    setActiveClasses(actives);
  };

  return (
    <ul className="navigation-content clearfix">
      <li className="frontpage-link">
        <Link to="/">
          <img
            src={`${STATIC_SERVER_URL}/web/images/header/khfc_logo_cropped.png`}
            alt="nyitóoldal"
          />
        </Link>
      </li>
      {menuItems.map((item, index) => {
        const isDisabled = !item.href;

        return (
          <li
            key={index}
            className={activeClasses[index] ? 'active' : ''}
            onClick={() => addActiveClass(index)}
          >
            {item.href ? (
              item.external ? (
                <a href={item.href} target="_blank" rel="noreferrer">
                  {item.label}
                </a>
              ) : (
                <Link to={item.href}>{item.label}</Link>
              )
            ) : (
              <a
                href={item.href || undefined}
                onClick={(e) => {
                  if (isDisabled) e.preventDefault();
                }}
                className={isDisabled ? 'disabled-link' : ''}
              >
                {item.label}
              </a>
            )}
            {item.submenu && (
              <div className="flex">
                <div className="navigation-submenu">
                  <ul>
                    {item.submenu.map((subitem, subindex) => (
                      <li key={subindex}>
                        {subitem.to ? (
                          <Link to={subitem.to}>{subitem.label}</Link>
                        ) : (
                          <a
                            href={subitem.href}
                            target={subitem.external ? '_blank' : '_self'}
                            rel="noreferrer"
                          >
                            {subitem.label}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <NavigationSocial />
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default NavigationContent;
